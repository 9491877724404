import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {

  @ViewChild('paypalRef') private paypalRef: ElementRef;
  @Input('amount') amount: number;
  @Output() approve = new EventEmitter();
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    window['paypal'].Buttons(
      {
        style: {
          layout: 'vertical',
          color: 'blue',
          shape: 'rect',
          label: 'paypal',
          size: 'responsive'
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: this.amount,
                  currency: 'EUR',
                }
              }
            ]
          })
        },

        onApprove: (data, actions) => {
          return actions.order.capture().then(details =>{
            this.approve.emit(details);
          })
        },

        onError: (error) => {
          alert(error);
          console.log(error);
        }
      }
    ).render(this.paypalRef.nativeElement)
  }

}
